import * as select2 from "select2";

export default class LocalitiesIndex {

    constructor() {
        this.url = "";
        this.bindEvents();
    }

    bindEvents(){
        let self = this;

        $('#js-activity-select').unbind("select2:select").on('select2:select', function (e) {
            window.location.href = self.url + "?activity_type=" + $(this).val();
        });
    }


    onDataChanged(data) {
        this.url = data.url;

        window.$('#js-activity-select').select2({
            theme: 'bootstrap4',
            templateResult: this.formatActivity,
            templateSelection: this.formatActivity,
            language: I18n.locale
        });

        $('b[role="presentation"]').hide();
        $('.select2-selection__arrow').append('<i class="fas fa-chevron-down fs-12"></i>');
        $('.select2-selection__arrow').addClass("custom_arrow_select2");
    }


    onDestroy(){
        window.$('#js-activity-select').select2('destroy');
        $('#js-activity-select').unbind("select2:select");
    }

    formatActivity(act) {
        let $act = "";
        if (!act.id) {
            return act.name;
        }else if($(act.element).val() === "0"){
            $act = $(
                '<span class="d-block">' + act.text + '</span>'
            );
        }else{
            $act = $(
                '<span class="lh-5">\n<span class="bg-secondary dot mr-2 fs-20"><i class="text-white icon icon-'+$(act.element).data("icon")+'"></i></span>' + act.text + '</span>'
            );
        }
        return $act;
    };
}