import L from 'leaflet';
import ToastNotification from "../utils/ToastNotification";
import ConfirmationModal from "../../common/ConfirmationModal";
import $ from 'jquery';

window.jquery = $
const API_HOST = $("meta[name=api-url]").attr("content");

const POINT_TYPES = {
  regular: { value: -1, label: 'Regular', icon: 'fa-location-dot' },
  take_off: { value: 0, label: 'Take Off', icon: 'fa-plane-departure' },
  sss: { value: 1, label: 'Start Speed Section', icon: 'fa-flag-checkered' },
  ess: { value: 2, label: 'End Speed Section', icon: 'fa-flag' },
  goal: { value: 3, label: 'Goal', icon: 'fa-trophy' },
  start_line: { value: 4, label: 'Start Line', icon: 'fa-hourglass-start' },
  waypoint: { value: 5, label: 'Waypoint', icon: 'fa-location-pin' },
  mark: { value: 6, label: 'Mark', icon: 'fa-map-marker' },
  turning_mark: { value: 7, label: 'Turning Mark', icon: 'fa-turn-up' },
  gate: { value: 10, label: 'Gate', icon: 'fa-door-open' },
  finish_line: { value: 11, label: 'Finish Line', icon: 'fa-flag-checkered' }
};

const waypointIcon = L.icon({
  iconUrl:
    "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fb/Map_pin_icon_green.svg/752px-Map_pin_icon_green.svg.png",
  iconRetinaUrl:
    "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fb/Map_pin_icon_green.svg/752px-Map_pin_icon_green.svg.png",
  iconSize: [25, 35],
  iconAnchor: [12, 35],
  popupAnchor: [1, -34]
});

const startIcon = L.icon({
  iconUrl: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png",
  iconRetinaUrl: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34]
});

const endIcon = L.icon({
  iconUrl: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png",
  iconRetinaUrl: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34]
});


const handleIcon = L.divIcon({
  className: 'track-handle',
  html: '<div style="width:12px;height:12px;border:1px solid blue;border-radius:50%;background:rgba(0,0,255,0.3);"></div>',
  iconSize: [12, 12],
  iconAnchor: [6, 6]
});


const invisibleHandleIcon = L.divIcon({
  className: 'track-handle-invisible',
  html: '<div style="width:12px;height:12px;"></div>',
  iconSize: [12, 12],
  iconAnchor: [6, 6]
});


const arrowIcon = L.divIcon({
  className: 'track-end-arrow',
  html: '<div>&#8594;</div>',
  iconSize: [24, 24],
  iconAnchor: [12, 12]
});

export class ContestTaskManager {
  constructor() {
    console.log("ContestTaskManager initialized");
    this.map = null;
    this.waypoints = [];
    this.markers = [];  

    this.confirmationModal = new ConfirmationModal();

    this.currentDefaultRadius = 1200;
    this.trackMode = false;
    this.trackHandles = [];
    this.currentTrackLayer = null;
    this.trackEndArrow = null;
    this.shortestPathLayer = null; 

    this.initMap();
    this.initMenu();
    this.initialize();
  }

  async initialize() {
    const path = window.location.pathname;
    const matches = path.match(/\/contest_task\/(\d+)$/);
    if (matches) {
      this.isEditMode = true;
      this.taskId = matches[1];
      await this.loadExistingTask();
    }
  }

  getTypeFromPointItemType(pointItemType) {
    const typeMapping = {
      0: 'take_off',
      1: 'sss',
      2: 'ess',
      3: 'goal',
      4: 'start_line',
      5: 'waypoint',
      6: 'mark',
      7: 'turning_mark',
      10: 'gate',
      11: 'finish_line'
    };
    return typeMapping[pointItemType] || 'waypoint';
  }

  getTypeFromServerType(serverType) {
    const typeMapping = {
      'REGULAR': 'regular',
      'TAKEOFF': 'take_off',
      'SSS': 'sss',
      'ESS': 'ess',
      'GOAL': 'goal',
      'START_LINE': 'start_line',
      'WAYPOINT': 'waypoint',
      'MARK': 'mark',
      'TURNING_MARK': 'turning_mark',
      'GATE': 'gate',
      'FINISH_LINE': 'finish_line'
    };
    return typeMapping[serverType] || 'waypoint';
  }

  async loadExistingTask() {
    try {
      const response = await fetch(`${API_HOST}/v1/contest_task/${this.taskId}`, {
        headers: {
          'X-STL-Token': cookies.get('STL-Token')
        }
      });

      if (!response.ok) {
        throw new Error('Failed to load task');
      }

      const data = await response.json();
      const { contest_task: taskData, points } = data;
      
      this.trackMode = true;

      
      let menuContent = document.getElementById('menu-content');
      if (!document.getElementById('taskNameInput')) {
        let settingsDiv = document.createElement("div");
        settingsDiv.className = "task-settings visible";
        settingsDiv.innerHTML = `
          <label>Task Name</label>
          <input id="taskNameInput" type="text">
          <div class="time-inputs">
            <div>
              <label>Start Time Begins</label>
              <input id="startTimeBeginsInput" type="datetime-local">
            </div>
            <div>
              <label>Start Time Ends</label>
              <input id="startTimeEndsInput" type="datetime-local">
            </div>
            <div>
              <label>Goal Deadline Time</label>
              <input id="goalDeadlineTimeInput" type="datetime-local">
            </div>
          </div>
        `;
        menuContent.insertBefore(settingsDiv, menuContent.firstChild);
      }

      
      document.getElementById('taskNameInput').value = taskData.name || '';
      document.getElementById('startTimeBeginsInput').value = taskData.start_time_begins?.split('.')[0] || '';
      document.getElementById('startTimeEndsInput').value = taskData.start_time_ends?.split('.')[0] || '';
      document.getElementById('goalDeadlineTimeInput').value = taskData.goal_deadline_time?.split('.')[0] || '';

      
      if (this.trackHandles) {
        this.trackHandles.forEach(handle => this.map.removeLayer(handle));
        this.trackHandles = [];
      }
      this.waypoints.forEach((_, index) => {
        this.deleteWaypoint(0);
      });

      
      const bounds = L.latLngBounds([]);
      const newHandles = [];

      points.forEach((point, index) => {
        const latlng = L.latLng(parseFloat(point.latitude), parseFloat(point.longitude));
        bounds.extend(latlng);

        const type = this.getTypeFromServerType(point.type_id);

        const wpIndex = this.addWaypoint(latlng, type);
        const marker = this.markers[wpIndex];
        
        const newLabelIcon = L.divIcon({
          className: 'waypoint-label',
          html: `<div class="waypoint-text">${point.name}</div>`,
          iconSize: [40, 20],
          iconAnchor: [20, 40]
        });
        marker.label.setIcon(newLabelIcon);
        
        if (marker.circle) {
          marker.circle.setRadius(point.radius);
        }

        const handle = L.marker(latlng, {
          icon: index === 0 || index === points.length - 1 ? invisibleHandleIcon : handleIcon,
          draggable: !(index === 0 || index === points.length - 1)
        }).addTo(this.map);
        handle.attachedWaypointIndex = wpIndex;
        handle.autoInserted = false;
        
        if (!(index === 0 || index === points.length - 1)) {
          this.attachHandleEvents(handle);
        }
        newHandles.push(handle);
      });

      this.trackHandles = newHandles;
      this.updateAutoHandles();
      
      
      this.map.fitBounds(bounds, {
        padding: [50, 50],
        maxZoom: 12
      });
      
      const addTrackBtn = document.getElementById('addTrackBtn');
      const saveTask = document.getElementById('saveTask');
      const exportTask = document.getElementById('exportTask');
      
      if (addTrackBtn) {
        addTrackBtn.style.display = 'none';
      }
      
      if (saveTask) {
        saveTask.style.display = 'block';
        saveTask.textContent = 'Update Task';
      }
      
      if (exportTask) {
        exportTask.style.display = 'block';
      }

      this._updateCurrentTrackFromHandles();
      this.showWaypoints();

    } catch (error) {
      console.error('Error loading task:', error);
      ToastNotification.make("Failed to load task data");
    }
  }

  updateMenu() {
    this.showWaypoints();
  }

  initMap() {
    this.map = L.map('map', {
      doubleClickZoom: false
    }).setView([46.4237013,11.8256556], 9); 
    
    
    this.map.on('contextmenu', (e) => {
      e.originalEvent.preventDefault();
      return false;
    });
    /*
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(this.map);
    */
    L.tileLayer('https://tile.tracestrack.com/topo__/{z}/{x}/{y}.png?key=5b37c4cf109fa26755308c3a5e221369', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(this.map);
    this.map.on('dblclick', (e) => this.addWaypoint(e.latlng));
  }

  updateMiddleHandlesNearWaypoint(waypointIndex) {
    if (!this.trackHandles || !this.trackHandles.length) return;
    
    
    this.trackHandles.forEach((handle, idx) => {
      if (handle.autoInserted) {
        const prev = this.trackHandles[idx - 1];
        const next = this.trackHandles[idx + 1];
        
        
        if ((prev && prev.attachedWaypointIndex === waypointIndex) ||
            (next && next.attachedWaypointIndex === waypointIndex)) {
          
          
          const prevPos = prev.getLatLng();
          const nextPos = next.getLatLng();
          const midLatLng = L.latLng(
            (prevPos.lat + nextPos.lat) / 2,
            (prevPos.lng + nextPos.lng) / 2
          );
          handle.setLatLng(midLatLng);
        }
      }
    });
  }

  addWaypoint(latlng, type = 'waypoint') {
    const index = this.waypoints.length;
    this.waypoints.push(latlng);
    
    
    if (type === 'start') {
      type = 'take_off';
    } else if (type === 'end') {
      type = 'goal';
    }
    
    let icon;
    switch (type) {
      case 'take_off':
        icon = startIcon;
        break;
      case 'goal':
        icon = endIcon;
        break;
      default:
        icon = waypointIcon;
    }
    
    const marker = L.marker(latlng, {
      icon: icon,
      draggable: true
    }).addTo(this.map);
    
    marker.waypointType = type;
    
    const labelText = type === 'take_off' ? 'Take Off' : 
                     type === 'goal' ? 'Goal' : 
                     `WP${index + 1}`;  
    
    const labelIcon = L.divIcon({
      className: 'waypoint-label',
      html: `<div class="waypoint-text">${labelText}</div>`,
      iconSize: [40, 20],
      iconAnchor: [20, 40]
    });
    const labelMarker = L.marker(latlng, {
      icon: labelIcon,
      interactive: false
    }).addTo(this.map);
    
    
    const circle = L.circle(latlng, { 
      radius: this.currentDefaultRadius,
      color: '#1579a3', 
      fill: true, 
      fillColor: '#1579a3', 
      fillOpacity: 0.3 
    }).addTo(this.map);
    
    this.markers.push({ marker, label: labelMarker, circle });

    marker.on('contextmenu', (e) => {
      e.originalEvent.preventDefault();
    });

    marker.on('click', () => {
      const idx = this.markers.findIndex(item => item.marker === marker);
      if (idx === -1) return;
      
      const currentDiameter = this.markers[idx].circle.getRadius() * 2;
      
      const currentLabelText = this.markers[idx].label.getIcon().options.html.match(/<div.*?>(.*?)<\/div>/)[1];
      
      const popup = L.popup({
        closeButton: false,
        autoClose: false,
        className: 'waypoint-properties-popup',
        maxWidth: 300
      })
        .setLatLng(marker.getLatLng())
        .setContent(`
          <div style="text-align: left; font-size: 12px;">
            <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 10px;">
              <h4 style="margin: 0;">Waypoint Properties</h4>
              ${type !== 'take_off' && type !== 'goal' ? 
                `<i class="fas fa-trash" style="cursor: pointer; color: #dc3545;" id="deleteWaypointBtn"></i>` : 
                ''}
            </div>
            <div style="margin-bottom: 10px;">
              <label>Name:</label><br>
              <input id="waypointNameInput" type="text" value="${currentLabelText}" style="width:100%;">
            </div>
            <div style="margin-bottom: 10px;">
              <label>Type:</label><br>
              <select id="waypointTypeSelect" style="width:100%;">
                ${Object.entries(POINT_TYPES).map(([key, type]) => `
                  <option value="${key}" ${marker.waypointType === key ? 'selected' : ''}>
                    <i class="fas ${type.icon}"></i> ${type.label}
                  </option>
                `).join('')}
              </select>
            </div>
            <div style="margin-bottom: 10px;">
              <label>Location:</label><br>
              <input id="waypointLatInput" type="number" step="0.0001" placeholder="Latitude" value="${marker.getLatLng().lat}" style="width:48%;margin-right:2%;">
              <input id="waypointLngInput" type="number" step="0.0001" placeholder="Longitude" value="${marker.getLatLng().lng}" style="width:48%;">
            </div>
            <div style="margin-bottom: 10px;">
              <label>Circle Diameter (m):</label><br>
              <input id="waypointDiameterInput" type="number" value="${currentDiameter}" style="width:100%;">
            </div>
            <div style="text-align: right; margin-top: 10px;">
              <button id="updateWaypointBtn" class="btn bg-secondary" style="margin-right: 5px; color: white;">Update</button>
              <button id="closeWaypointBtn" style="background: transparent; border: none">Cancel</button>
            </div>
          </div>
        `)
        .openOn(this.map);

      setTimeout(() => {
        const deleteBtn = document.getElementById('deleteWaypointBtn');
        const updateBtn = document.getElementById('updateWaypointBtn');
        const closeBtn = document.getElementById('closeWaypointBtn');
        const nameInput = document.getElementById('waypointNameInput');
        const typeSelect = document.getElementById('waypointTypeSelect');
        const latInput = document.getElementById('waypointLatInput');
        const lngInput = document.getElementById('waypointLngInput');
        const diameterInput = document.getElementById('waypointDiameterInput');

        if (deleteBtn) {
          deleteBtn.addEventListener('click', () => {
            this.map.closePopup();
            setTimeout(() => {
              this.deleteWaypoint(idx);
            }, 50);
          });
        }

        updateBtn.addEventListener('click', () => {
          
          const newName = nameInput.value.trim();
          const newLabelIcon = L.divIcon({
            className: 'waypoint-label',
            html: `<div class="waypoint-text">${newName}</div>`,
            iconSize: [40, 20],
            iconAnchor: [20, 40]
          });
          this.markers[idx].label.setIcon(newLabelIcon);

          
          const newType = typeSelect.value;
          marker.waypointType = newType; 
          let newIcon;
          switch (newType) {
            case 'take_off':
              newIcon = startIcon;
              break;
            case 'goal':
              newIcon = endIcon;
              break;
            default:
              newIcon = waypointIcon;
          }
          marker.setIcon(newIcon);
          marker.waypointType = newType;

          
          const newLat = parseFloat(latInput.value);
          const newLng = parseFloat(lngInput.value);
          if (!isNaN(newLat) && !isNaN(newLng)) {
            const newLatLng = L.latLng(newLat, newLng);
            marker.setLatLng(newLatLng);
            this.markers[idx].label.setLatLng(newLatLng);
            this.markers[idx].circle.setLatLng(newLatLng);
            this.waypoints[idx] = newLatLng;
          }

          
          const newDiameter = parseFloat(diameterInput.value);
          if (!isNaN(newDiameter) && newDiameter >= 0) {
            this.markers[idx].circle.setRadius(newDiameter / 2);
          }

          this.map.closePopup();
          this._updateCurrentTrackFromHandles();
          this.showWaypoints();
        });

        closeBtn.addEventListener('click', () => {
          this.map.closePopup();
        });
      }, 10);
    });

    marker.on('drag', (e) => {
      const newLatLng = e.target.getLatLng();
      labelMarker.setLatLng(newLatLng);
      if (circle) {
        circle.setLatLng(newLatLng);
      }
      const markerIndex = this.markers.findIndex(item => item.marker === marker);
      this.waypoints[markerIndex] = newLatLng;
      if (this.trackHandles && this.trackHandles.length) {
        this.trackHandles.forEach(handle => {
          if (handle.attachedWaypointIndex === markerIndex) {
            handle.setLatLng(newLatLng);
          }
        });
        this.updateMiddleHandlesNearWaypoint(markerIndex);
        this._updateCurrentTrackFromHandles();
      }
    });
    marker.on('dragend', () => {
      this.showWaypoints();
    });
    this.updateMenu();
    return this.waypoints.length - 1; 
  }

  deleteWaypoint(index) {
    const item = this.markers[index];
    this.map.removeLayer(item.marker);
    this.map.removeLayer(item.label);
    if (item.circle) {
      this.map.removeLayer(item.circle);
    }
    this.waypoints.splice(index, 1);
    this.markers.splice(index, 1);
    
    this.markers.forEach((item, i) => {
      const newLabelIcon = L.divIcon({
        className: 'waypoint-label',
        html: `<div class="waypoint-text">wpt ${i + 1}</div>`,
        iconSize: [40, 20],
        iconAnchor: [20, 40]
      });
      item.label.setIcon(newLabelIcon);
    });
    
    if (this.trackMode && this.trackHandles.length) {
      
      const attachedHandleIndex = this.trackHandles.findIndex(h => h.attachedWaypointIndex === index);
      
      if (attachedHandleIndex !== -1) {
        
        let prevHandle = null;
        let nextHandle = null;
        
        
        for (let i = attachedHandleIndex - 1; i >= 0; i--) {
          if (this.trackHandles[i].attachedWaypointIndex !== null) {
            prevHandle = this.trackHandles[i];
            break;
          }
        }
        
        for (let i = attachedHandleIndex + 1; i < this.trackHandles.length; i++) {
          if (this.trackHandles[i].attachedWaypointIndex !== null) {
            nextHandle = this.trackHandles[i];
            break;
          }
        }

        if (prevHandle && nextHandle) {
          
          const startIdx = this.trackHandles.indexOf(prevHandle);
          const endIdx = this.trackHandles.indexOf(nextHandle);
          const handlesBetween = this.trackHandles.slice(startIdx + 1, endIdx);
          handlesBetween.forEach(h => this.map.removeLayer(h));
          
          const midLatLng = L.latLng(
            (prevHandle.getLatLng().lat + nextHandle.getLatLng().lat) / 2,
            (prevHandle.getLatLng().lng + nextHandle.getLatLng().lng) / 2
          );
          const midHandle = L.marker(midLatLng, {
            icon: handleIcon,
            draggable: true
          }).addTo(this.map);
          midHandle.attachedWaypointIndex = null;
          midHandle.autoInserted = true;
          this.attachHandleEvents(midHandle);

          
          this.trackHandles = [
            ...this.trackHandles.slice(0, startIdx + 1),
            midHandle,
            ...this.trackHandles.slice(endIdx)
          ];
        }
        
        this.trackHandles.forEach(handle => {
          if (handle.attachedWaypointIndex > index) {
            handle.attachedWaypointIndex--;
          }
        });
      }

      this._updateCurrentTrackFromHandles();
    }

    this.updateMenu();
    this.map.closePopup();
  }
 
  startTrackMode() {
    console.log("startTrackMode: Entering track mode");
    this.trackMode = true;
    if (this.trackHandles && this.trackHandles.length) {
      this.trackHandles.forEach(handle => this.map.removeLayer(handle));
    }
    if (this.trackEndArrow) {
      this.map.removeLayer(this.trackEndArrow);
      this.trackEndArrow = null;
    }
    
    const center = this.map.getCenter();
    const offset = 0.15;
    const startLatLng = L.latLng(center.lat, center.lng - offset);
    const endLatLng = L.latLng(center.lat, center.lng + offset);
    
    const startWaypointIndex = this.addWaypoint(startLatLng, 'start');
    const endWaypointIndex = this.addWaypoint(endLatLng, 'end');

    const startHandle = L.marker(startLatLng, {
      icon: invisibleHandleIcon,
      draggable: false  
    }).addTo(this.map);
    startHandle.attachedWaypointIndex = startWaypointIndex;  
    startHandle.autoInserted = false;
    const endHandle = L.marker(endLatLng, {
      icon: invisibleHandleIcon,
      draggable: false  
    }).addTo(this.map);
    endHandle.attachedWaypointIndex = endWaypointIndex;  
    endHandle.autoInserted = false;
    this.trackHandles = [startHandle, endHandle];
    
    const midLatLng = L.latLng(
      (startLatLng.lat + endLatLng.lat) / 2,
      (startLatLng.lng + endLatLng.lng) / 2
    );
    const midHandle = L.marker(midLatLng, {
      icon: handleIcon,
      draggable: true
    }).addTo(this.map);
    midHandle.attachedWaypointIndex = null;
    midHandle.autoInserted = true;
    this.attachHandleEvents(midHandle);
    
    this.trackHandles = [startHandle, midHandle, endHandle];
    
    this._updateCurrentTrackFromHandles();
    this.showWaypoints();
  }

  handleDisconnection(handle) {
    
    const handleIndex = this.trackHandles.indexOf(handle);
    let prevHandle = null;
    let nextHandle = null;
    
    
    for (let i = handleIndex - 1; i >= 0; i--) {
      if (this.trackHandles[i].attachedWaypointIndex !== null) {
        prevHandle = this.trackHandles[i];
        break;
      }
    }
    
    
    for (let i = handleIndex + 1; i < this.trackHandles.length; i++) {
      if (this.trackHandles[i].attachedWaypointIndex !== null) {
        nextHandle = this.trackHandles[i];
        break;
      }
    }

    if (prevHandle && nextHandle) {
      
      const startIdx = this.trackHandles.indexOf(prevHandle);
      const endIdx = this.trackHandles.indexOf(nextHandle);
      const handlesBetween = this.trackHandles.slice(startIdx + 1, endIdx);
      handlesBetween.forEach(h => this.map.removeLayer(h));
      
      
      const midLatLng = L.latLng(
        (prevHandle.getLatLng().lat + nextHandle.getLatLng().lat) / 2,
        (prevHandle.getLatLng().lng + nextHandle.getLatLng().lng) / 2
      );
      const midHandle = L.marker(midLatLng, {
        icon: handleIcon,
        draggable: true
      }).addTo(this.map);
      midHandle.attachedWaypointIndex = null;
      midHandle.autoInserted = true;
      this.attachHandleEvents(midHandle);

      
      this.trackHandles = [
        ...this.trackHandles.slice(0, startIdx + 1),
        midHandle,
        ...this.trackHandles.slice(endIdx)
      ];
    }
  }

  attachHandleEvents(handle) {
    handle.on('dragend', () => {
      const pos = handle.getLatLng();
      let snapped = false;
      const wasAttached = handle.attachedWaypointIndex !== null;
      
      const isEndpoint = this.trackHandles.indexOf(handle) === 0 || 
                        this.trackHandles.indexOf(handle) === this.trackHandles.length - 1;
      
      if (isEndpoint && wasAttached) {
        handle.setLatLng(this.waypoints[handle.attachedWaypointIndex]);
        return;
      }

      for (let i = 0; i < this.waypoints.length; i++) {
        const wp = this.waypoints[i];
        if (pos.distanceTo(wp) < 800) {
          const isWaypointTaken = this.trackHandles.some(h => 
            h !== handle && h.attachedWaypointIndex === i
          );
          
          if (!isWaypointTaken) {
            handle.setLatLng(wp);
            handle.attachedWaypointIndex = i;
            snapped = true;
            break;
          }
        }
      }
      
      if (!snapped && !isEndpoint) {
        if (wasAttached) {
          handle.attachedWaypointIndex = null;
        }
        
        const newWaypointIndex = this.addWaypoint(pos);
        handle.attachedWaypointIndex = newWaypointIndex;
        handle.setLatLng(this.waypoints[newWaypointIndex]);
      }
      
      this.updateAutoHandles();
      this._updateCurrentTrackFromHandles();
    });
    
    handle.on('drag', () => {
      this._updateCurrentTrackFromHandles();
    });
  }

  updateAutoHandles() {
   
    let currentHandles = this.trackHandles.slice();
    let newHandles = [];
    for (let i = 0; i < currentHandles.length - 1; i++) {
      newHandles.push(currentHandles[i]);
      let A = currentHandles[i];
      let B = currentHandles[i + 1];
      if (A.attachedWaypointIndex != null && B.attachedWaypointIndex != null) {
        let distance = A.getLatLng().distanceTo(B.getLatLng());
        if (distance > 800) {  
          let midLatLng = L.latLng(
            (A.getLatLng().lat + B.getLatLng().lat) / 2,
            (A.getLatLng().lng + B.getLatLng().lng) / 2
          );
          let autoHandle = L.marker(midLatLng, {
            icon: handleIcon,
            draggable: true
          }).addTo(this.map);
          autoHandle.attachedWaypointIndex = null;
          autoHandle.autoInserted = true;
          this.attachHandleEvents(autoHandle);
          newHandles.push(autoHandle);
        }
      }
    }
    if (currentHandles.length > 0) {
      newHandles.push(currentHandles[currentHandles.length - 1]);
    }
    this.trackHandles = newHandles;
  }

 
  _updateCurrentTrackFromHandles() {
    const positions = this.trackHandles.map(handle => handle.getLatLng());
    if (this.currentTrackLayer) {
      this.map.removeLayer(this.currentTrackLayer);
    }
    this.currentTrackLayer = L.polyline(positions, {
      color: '#005478'
    }).addTo(this.map);
   
    if (this.trackEndArrow) {
      this.map.removeLayer(this.trackEndArrow);
    }
    if (positions.length >= 2) {
      this.trackEndArrow = L.polylineDecorator(this.currentTrackLayer, {
        patterns: [
          {
            offset: '100%',
            repeat: 0,
            symbol: L.Symbol.arrowHead({
              pixelSize: 15,
              polygon: false,
              pathOptions: { stroke: true, color: '#005478' }
            })
          }
        ]
      }).addTo(this.map);
    }
    this.updateTrackInfo();
    this.updateShortestPath(); 
  }
  
  updateShortestPath() {
    if (this.waypoints.length < 2) return;
    
    if (this.shortestPathLayer) {
      this.map.removeLayer(this.shortestPathLayer);
    }
    
    const orderedWaypoints = [];
    this.trackHandles.forEach((handle) => {
      if (handle.attachedWaypointIndex !== null) {
        orderedWaypoints.push({
          point: this.waypoints[handle.attachedWaypointIndex],
          radius: this.markers[handle.attachedWaypointIndex].circle?.getRadius() || 0
        });
      }
    });

    if (orderedWaypoints.length < 2) return;
    
    const pathPoints = [];
    pathPoints.push(orderedWaypoints[0].point); 

    for (let i = 1; i < orderedWaypoints.length - 1; i++) {
      const prev = orderedWaypoints[i - 1];
      const curr = orderedWaypoints[i];
      const next = orderedWaypoints[i + 1];
      
      if (curr.radius === 0) {
        pathPoints.push(curr.point);
        continue;
      }
      
      const optimalPoint = this.findOptimalPointOnCircle(
        prev.point,
        curr.point,
        next.point,
        curr.radius
      );
      pathPoints.push(optimalPoint);
    }

    pathPoints.push(orderedWaypoints[orderedWaypoints.length - 1].point); 
    
    this.shortestPathLayer = L.polyline(pathPoints, {
      color: 'red',
      weight: 3,
      dashArray: '5,5'
    }).addTo(this.map);
  }

  findOptimalPointOnCircle(prevPoint, circleCenter, nextPoint, radius) {
    
    const scale = Math.cos(circleCenter.lat * Math.PI / 180) * 111111;
    const x0 = (prevPoint.lng - circleCenter.lng) * scale;
    const y0 = (prevPoint.lat - circleCenter.lat) * 111111;
    const x1 = (nextPoint.lng - circleCenter.lng) * scale;
    const y1 = (nextPoint.lat - circleCenter.lat) * 111111;
    
    const anglePrev = Math.atan2(y0, x0);
    const angleNext = Math.atan2(y1, x1);
    
    let optimalAngle = (anglePrev + angleNext) / 2;
    if (Math.abs(angleNext - anglePrev) > Math.PI) {
      optimalAngle += Math.PI;
    }
    
    return L.latLng(
      circleCenter.lat + (Math.sin(optimalAngle) * radius) / 111111,
      circleCenter.lng + (Math.cos(optimalAngle) * radius) / scale
    );
  }

  finishTrackMode() {
    console.log("finishTrackMode: Exiting track mode");
    this.trackMode = false;
    if (this.trackHandles && this.trackHandles.length) {
      this.trackHandles.forEach(handle => this.map.removeLayer(handle));
      this.trackHandles = [];
    }
    if (this.trackEndArrow) {
      this.map.removeLayer(this.trackEndArrow);
      this.trackEndArrow = null;
    }
    document.getElementById('addTrackBtn').style.display = 'block';
    this.updateTrackInfo();
    this.showWaypoints();
  }

 
  computeTotalWaypointDistance() {
    let total = 0;
    for (let i = 1; i < this.waypoints.length; i++) {
      total += this.waypoints[i - 1].distanceTo(this.waypoints[i]);
    }
    return total.toFixed(2);
  }

  computeTrackDistance() {
    let total = 0;
    if (this.trackHandles && this.trackHandles.length > 1) {
      for (let i = 1; i < this.trackHandles.length; i++) {
        total += this.trackHandles[i - 1].getLatLng().distanceTo(this.trackHandles[i].getLatLng());
      }
    }
    return total.toFixed(2);
  }

  updateTrackInfo() {
    const trackInfoDiv = document.getElementById('track-info');
    if (this.trackMode) {
      let trackDistance = this.computeTrackDistance();
      trackInfoDiv.innerHTML = `<strong>Track Info:</strong><br>Total Track Distance: ${trackDistance} m<br>Track Points: ${this.trackHandles.length}`;
    } else {
      let numWpts = this.waypoints.length;
      let totalDist = this.computeTotalWaypointDistance();
      trackInfoDiv.innerHTML = `<strong>Track Info:</strong><br>Total Track Distance: 0.00 m<br>Track Points: 0<br>Total Waypoint Distance: ${totalDist} m<br>Waypoints: ${numWpts}`;
    }
  }
 
  initMenu() {
    const addTrackBtn = document.getElementById('addTrackBtn');
    const saveTaskBtn = document.getElementById('saveTask');
    const exportTaskBtn = document.getElementById('exportTask');
    const importTaskBtn = document.getElementById('importTask');
    const importFileInput = document.createElement('input');
    importFileInput.type = 'file';
    importFileInput.accept = '.xctsk';
    importFileInput.style.display = 'none';
    document.body.appendChild(importFileInput);
    
    addTrackBtn.addEventListener('click', () => {
      console.log("Add Track button clicked, calling startTrackMode");
      this.startTrackMode();
      addTrackBtn.style.display = 'none';
      if (saveTaskBtn) {
        saveTaskBtn.style.display = 'block';
      }
      exportTaskBtn.style.display = 'block';
    });

    importTaskBtn.addEventListener('click', () => {
      importFileInput.click();
    });

    importFileInput.addEventListener('change', (e) => {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          try {
            const taskData = JSON.parse(e.target.result);
            this.importXCTSK(taskData);
          } catch (error) {
            console.error('Error parsing XCTSK file:', error);
            ToastNotification.make("Invalid XCTSK file format");
          }
        };
        reader.readAsText(file);
      }
    });

    if (saveTaskBtn) {
      saveTaskBtn.addEventListener('click', () => {
        this.saveContestTask();
      });
    }

    exportTaskBtn.addEventListener('click', () => {
      this.exportXCTSK();
    });
    
    if (saveTaskBtn) {
      saveTaskBtn.style.display = 'none';
    }
    exportTaskBtn.style.display = 'none';
    
    this.showWaypoints();
  }

  importXCTSK(taskData) {
    if (!taskData.turnpoints || !Array.isArray(taskData.turnpoints)) {
      ToastNotification.make('Invalid XCTSK file: missing turnpoints array');
      return;
    }

    if (this.trackHandles) {
      this.trackHandles.forEach(handle => this.map.removeLayer(handle));
      this.trackHandles = [];
    }
    this.waypoints.forEach((_, index) => {
      this.deleteWaypoint(0);
    });

    this.trackMode = true;

    const bounds = L.latLngBounds([]);
    const newHandles = [];
    
    
    const connectedTurnpoints = taskData.turnpoints.filter(tp => tp.type !== 'MARK');
    const unconnectedTurnpoints = taskData.turnpoints.filter(tp => tp.type === 'MARK');
    
    
    connectedTurnpoints.forEach((tp, index) => {
      const latlng = L.latLng(tp.waypoint.lat, tp.waypoint.lon);
      bounds.extend(latlng);
      let type;
      
      switch (tp.type) {
        case 'TAKEOFF':
          type = 'take_off';
          break;
        case 'SSS':
          type = 'sss';
          break;
        case 'ESS':
          type = 'ess';
          break;
        case 'GOAL':
          type = 'goal';
          break;
        default:
          type = 'waypoint';
      }

      const wpIndex = this.addWaypoint(latlng, type);
      const marker = this.markers[wpIndex];
      
      const newLabelIcon = L.divIcon({
        className: 'waypoint-label',
        html: `<div class="waypoint-text">${tp.waypoint.name}</div>`,
        iconSize: [40, 20],
        iconAnchor: [20, 40]
      });
      marker.label.setIcon(newLabelIcon);
      if (marker.circle) {
        marker.circle.setRadius(tp.radius);
      }

      
      const handle = L.marker(latlng, {
        icon: index === 0 || index === connectedTurnpoints.length - 1 ? invisibleHandleIcon : handleIcon,
        draggable: !(index === 0 || index === connectedTurnpoints.length - 1)
      }).addTo(this.map);
      handle.attachedWaypointIndex = wpIndex;
      handle.autoInserted = false;
      if (!(index === 0 || index === connectedTurnpoints.length - 1)) {
        this.attachHandleEvents(handle);
      }
      newHandles.push(handle);
    });

    
    unconnectedTurnpoints.forEach(tp => {
      const latlng = L.latLng(tp.waypoint.lat, tp.waypoint.lon);
      bounds.extend(latlng);
      
      const wpIndex = this.addWaypoint(latlng, 'mark');
      const marker = this.markers[wpIndex];
      
      const newLabelIcon = L.divIcon({
        className: 'waypoint-label',
        html: `<div class="waypoint-text">${tp.waypoint.name}</div>`,
        iconSize: [40, 20],
        iconAnchor: [20, 40]
      });
      marker.label.setIcon(newLabelIcon);
      if (marker.circle) {
        marker.circle.setRadius(tp.radius);
      }
      
    });

    this.trackHandles = newHandles;
    this.updateAutoHandles();
    
    this.map.fitBounds(bounds, {
      padding: [50, 50],
      maxZoom: 12
    });
    
    if (taskData.takeoff) {
      if (taskData.takeoff.timeOpen) {
        document.getElementById('startTimeBeginsInput').value = 
          taskData.takeoff.timeOpen.split('.')[0];
      }
      if (taskData.takeoff.timeClose) {
        document.getElementById('startTimeEndsInput').value = 
          taskData.takeoff.timeClose.split('.')[0];
      }
    }
    
    if (taskData.goal?.deadline) {
      document.getElementById('goalDeadlineTimeInput').value = 
        taskData.goal.deadline.split('.')[0];
    }

    document.getElementById('addTrackBtn').style.display = 'none';
    const saveTask = document.getElementById('saveTask');
    if (saveTask) {
      document.getElementById('saveTask').style.display = 'block';
    }
    document.getElementById('exportTask').style.display = 'block';

    this._updateCurrentTrackFromHandles();
    this.showWaypoints();
  }

  exportXCTSK() {
    if (!this.trackMode || !this.trackHandles || this.trackHandles.length < 2) {
      ToastNotification.make('Task must have at least 2 waypoints');
      return;
    }

    const taskDate = new Date();
    taskDate.setDate(taskDate.getDate() + 1);
    
    const startTimeBegins = document.getElementById('startTimeBeginsInput')?.value 
      ? new Date(document.getElementById('startTimeBeginsInput').value).toISOString()
      : new Date(taskDate.setHours(10, 0, 0, 0)).toISOString();
      
    const startTimeEnds = document.getElementById('startTimeEndsInput')?.value
      ? new Date(document.getElementById('startTimeEndsInput').value).toISOString()
      : new Date(taskDate.setHours(12, 0, 0, 0)).toISOString();
      
    const goalDeadline = document.getElementById('goalDeadlineTimeInput')?.value
      ? new Date(document.getElementById('goalDeadlineTimeInput').value).toISOString()
      : new Date(taskDate.setHours(18, 0, 0, 0)).toISOString();

    
    const connectedWaypointIndices = new Set(
      this.trackHandles
        .filter(handle => handle.attachedWaypointIndex !== null)
        .map(handle => handle.attachedWaypointIndex)
    );

    
    const allWaypoints = this.waypoints.map((waypoint, index) => ({
      index,
      waypoint,
      marker: this.markers[index].marker,
      circle: this.markers[index].circle,
      label: this.markers[index].label,
      isConnected: connectedWaypointIndices.has(index)
    }));

    
    const sortedWaypoints = [
      
      ...this.trackHandles
        .filter(handle => handle.attachedWaypointIndex !== null)
        .map(handle => allWaypoints[handle.attachedWaypointIndex]),
      
      ...allWaypoints.filter(wp => !wp.isConnected)
    ];

    if (sortedWaypoints.length < 2) {
      ToastNotification.make('Task must have at least 2 waypoints');
      return;
    }

    const taskData = {
      taskType: "CLASSIC",
      version: 1,
      earthModel: "WGS84",
      turnpoints: sortedWaypoints.map((item, index) => {
        const isFirst = index === 0;
        const isLast = index === sortedWaypoints.findIndex(wp => wp.isConnected) === index;
        
        let type;
        if (!item.isConnected) {
          type = 'MARK'; 
        } else if (isFirst) {
          type = 'TAKEOFF';
        } else if (isLast) {
          type = 'ESS';
        } else {
          switch (item.marker.waypointType) {
            case 'sss': type = 'SSS'; break;
            case 'ess': type = 'ESS'; break;
            default: type = null;
          }
        }

        const name = item.label.getIcon().options.html.match(/<div.*?>(.*?)<\/div>/)[1];

        return {
          ...(type && { type }),
          radius: item.circle ? item.circle.getRadius() : 400,
          waypoint: {
            name: name,
            description: item.isConnected ? `Task waypoint ${index + 1}` : 'Unconnected waypoint',
            lat: item.waypoint.lat,
            lon: item.waypoint.lng,
            altSmoothed: 1000
          }
        };
      }),
      takeoff: {
        timeOpen: startTimeBegins,
        timeClose: startTimeEnds
      },
      sss: {
        type: "RACE",
        timeGates: [startTimeBegins]
      },
      goal: {
        type: "CYLINDER",
        deadline: goalDeadline
      }
    };

    const blob = new Blob([JSON.stringify(taskData, null, 2)], { type: 'application/xctsk' });
    const url = URL.createObjectURL(blob);
    const filename = document.getElementById('taskNameInput')?.value || 'task';
    const a = document.createElement('a');
    a.href = url;
    a.download = `${filename.replace(/\s+/g, '_')}.xctsk`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }

  buildContestTaskData() {
    const taskDate = new Date();
    taskDate.setDate(taskDate.getDate() + 1);
    taskDate.setHours(10, 0, 0, 0);
  
    const startTimeBegins = new Date(taskDate);
    const startTimeEnds = new Date(taskDate);
    startTimeEnds.setHours(12, 0, 0, 0);
    const goalDeadlineTime = new Date(taskDate);
    goalDeadlineTime.setHours(18, 0, 0, 0);
    
    let orderedWaypoints = this.trackMode && this.trackHandles ? 
      this.trackHandles
        .filter(handle => handle.attachedWaypointIndex !== null)
        .map(handle => ({
          index: handle.attachedWaypointIndex,
          waypoint: this.waypoints[handle.attachedWaypointIndex],
          marker: this.markers[handle.attachedWaypointIndex]
        })) :
      this.waypoints.map((waypoint, index) => ({
        index,
        waypoint,
        marker: this.markers[index]
      }));

    const points = orderedWaypoints.map((item, index, array) => {
      const { waypoint, marker } = item;
      const markerType = marker.marker.waypointType;

      let pointItemType = POINT_TYPES.regular.value;
      if (POINT_TYPES[markerType]) {
        pointItemType = POINT_TYPES[markerType].value;
      }

      return {
        point_item_type: pointItemType,
        name: marker.label.getIcon().options.html.match(/<div.*?>(.*?)<\/div>/)[1],
        description: `Task waypoint ${index + 1}`,
        latitude: waypoint.lat.toString(),
        longitude: waypoint.lng.toString(),
        elevation: 1000,
        radius: marker.circle ? marker.circle.getRadius() : 400
      };
    });

    const data = {
      contest_task: {
        name: document.getElementById('taskNameInput')?.value || "New Contest Task",
        category: parseInt(document.querySelector('meta[name="category-id"]').content) || 1,
        start_time_begins: document.getElementById('startTimeBeginsInput')?.value || startTimeBegins.toISOString(),
        start_time_ends: document.getElementById('startTimeEndsInput')?.value || startTimeEnds.toISOString(),
        goal_deadline_time: document.getElementById('goalDeadlineTimeInput')?.value || goalDeadlineTime.toISOString(),
        points: points
      }
    };

    return data;
  }

  saveContestTask() {
    if (this.waypoints.length < 2) {
      ToastNotification.make('Task must have at least 2 waypoints');
      return;
    }

    const data = this.buildContestTaskData();
    const url = this.isEditMode ? 
      `${API_HOST}/v1/contest_task/${this.taskId}` :
      `${API_HOST}/v1/contest_task/`;

    fetch(url, {
      method: this.isEditMode ? 'PUT' : 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-STL-Token': cookies.get('STL-Token')
      },
      body: JSON.stringify(data)
    })
    .then(response => {
      if (!response.ok) {
        return response.json().then(errorData => {
          throw new Error(errorData.error || 'Network response was not ok');
        });
      }
      return response.json();
    })
    .then(result => {
      if (result.id) {
        if (!this.isEditMode) {
          window.location.href = `/contest_task/${result.id}`;
        } else {
          console.log('Task saved successfully:', result);
          ToastNotification.make('Task updated successfully');
        }
      } else {
        throw new Error('Invalid response format');
      }
    })
    .catch(error => {
      console.error('Error saving task:', error);
      ToastNotification.make(error.message || 'Failed to save task. Please try again.');
    });
  }

  showWaypoints() {
    const menuContent = document.getElementById('menu-content');
    menuContent.dataset.active = 'waypoints';
    
    
    const oldSettings = {
      taskName: document.getElementById('taskNameInput')?.value,
      startTimeBegins: document.getElementById('startTimeBeginsInput')?.value,
      startTimeEnds: document.getElementById('startTimeEndsInput')?.value,
      goalDeadline: document.getElementById('goalDeadlineTimeInput')?.value
    };
    
    menuContent.innerHTML = "";
    this.updateTrackInfo();
    
    if (this.trackMode) {
      let settingsDiv = document.createElement("div");
      settingsDiv.className = "task-settings visible";
      settingsDiv.innerHTML = `
        <label>Task Name</label>
        <input id="taskNameInput" type="text" value="${oldSettings.taskName || 'New Contest Task'}">
        <div class="time-inputs">
          <div>
            <label>Start Time Begins</label>
            <input id="startTimeBeginsInput" type="datetime-local" value="${oldSettings.startTimeBegins || ''}">
          </div>
          <div>
            <label>Start Time Ends</label>
            <input id="startTimeEndsInput" type="datetime-local" value="${oldSettings.startTimeEnds || ''}">
          </div>
          <div>
            <label>Goal Deadline Time</label>
            <input id="goalDeadlineTimeInput" type="datetime-local" value="${oldSettings.goalDeadline || ''}">
          </div>
        </div>
      `;
      menuContent.appendChild(settingsDiv);
    }

    
    let table = document.createElement("table");
    let thead = document.createElement("thead");
    let headerRow = document.createElement("tr");
   
    ["", "Name", "Type", "Distance (m)", "Radius (m)", "Action"].forEach(text => {
      let th = document.createElement("th");
      th.textContent = text;
      headerRow.appendChild(th);
    });
    thead.appendChild(headerRow);
    table.appendChild(thead);
    
    let tbody = document.createElement("tbody");
    tbody.id = 'waypoints-tbody';
    
    
    const waypointOrder = {};
    if (this.trackMode && this.trackHandles) {
      this.trackHandles.forEach((handle, index) => {
        if (handle.attachedWaypointIndex !== null) {
          waypointOrder[handle.attachedWaypointIndex] = index + 1;
        }
      });
    }

    const waypointIndices = Array.from({ length: this.waypoints.length }, (_, i) => i)
      .sort((a, b) => {
        const orderA = waypointOrder[a] || Infinity;
        const orderB = waypointOrder[b] || Infinity;
        return orderA - orderB;
      });

    waypointIndices.forEach((i) => {
      let row = document.createElement("tr");
      const marker = this.markers[i].marker;
      const waypointType = marker.waypointType;
      
      
      row.dataset.waypointIndex = i;
      row.dataset.type = waypointType;
      row.draggable = waypointType !== 'start' && waypointType !== 'end';
      
      
      let tdDrag = document.createElement("td");
      let dragIcon = document.createElement("i");
      dragIcon.className = "fas fa-arrows-alt drag-handle";
      dragIcon.style = "color: white;"
      if (waypointType !== 'start' && waypointType !== 'end') {
        tdDrag.style.cursor = "move";
      }
      tdDrag.appendChild(dragIcon);
      row.appendChild(tdDrag);
      
      let tdName = document.createElement("td");
      const labelText = this.markers[i].label.getIcon().options.html.match(/<div.*?>(.*?)<\/div>/)[1];
      tdName.textContent = labelText;
      row.appendChild(tdName);

      
      let tdType = document.createElement("td");
      tdType.textContent = waypointType; 
      row.appendChild(tdType);

      let tdDistance = document.createElement("td");
      if (i === waypointIndices[0]) { 
        tdDistance.textContent = "-";
      } else {
        const prevIndex = waypointIndices[waypointIndices.indexOf(i) - 1];
        const dist = this.waypoints[prevIndex].distanceTo(this.waypoints[i]).toFixed(0);
        tdDistance.textContent = `${dist}m`;
      }
      row.appendChild(tdDistance);
      
      let tdRadius = document.createElement("td");
      let inputRadius = document.createElement("input");
      inputRadius.type = "number";
      inputRadius.style.width = "80px";
      let currentDiameter = this.markers[i].circle ? this.markers[i].circle.getRadius() * 2 : 0;
      inputRadius.value = currentDiameter;
      
      const updateRadius = () => {
        let newDiameter = parseFloat(inputRadius.value);
        if (!isNaN(newDiameter) && newDiameter >= 0) {
          if (this.markers[i].circle) {
            this.markers[i].circle.setRadius(newDiameter / 2);
          }
        }
      };
      
      inputRadius.addEventListener('blur', updateRadius);
      inputRadius.addEventListener('keypress', (e) => {
        if (e.key === 'Enter') {
          updateRadius();
          inputRadius.blur();
        }
      });
      
      tdRadius.appendChild(inputRadius);
      row.appendChild(tdRadius);
      
      let tdAction = document.createElement("td");
      if (waypointType !== 'start' && waypointType !== 'end') {
        let deleteIcon = document.createElement("i");
        deleteIcon.className = "fas fa-trash";
        deleteIcon.style.cursor = "pointer";
        deleteIcon.style.color = "#dc3545";
        deleteIcon.addEventListener("click", () => {
          this.deleteWaypoint(i);
        });
        tdAction.appendChild(deleteIcon);
      }
      row.appendChild(tdAction);
      
      tbody.appendChild(row);
    });
    
    table.appendChild(tbody);
    menuContent.appendChild(table);
    
    this.initializeTableDragAndDrop(tbody);
  }

  initializeTableDragAndDrop(tbody) {
    let draggedRow = null;
    let originalIndex = null;

    tbody.addEventListener('dragstart', (e) => {
      draggedRow = e.target.closest('tr');
      if (!draggedRow || draggedRow.dataset.type === 'take_off' || draggedRow.dataset.type === 'goal') {
        e.preventDefault();
        return;
      }
      originalIndex = Array.from(tbody.children).indexOf(draggedRow);
      draggedRow.classList.add('dragging');
    });

    tbody.addEventListener('dragover', (e) => {
      e.preventDefault();
      if (!draggedRow) return;

      const targetRow = e.target.closest('tr');
      if (!targetRow || targetRow === draggedRow) return;

      const targetIndex = Array.from(tbody.children).indexOf(targetRow);
      const startRow = Array.from(tbody.children).find(row => row.dataset.type === 'take_off');
      const endRow = Array.from(tbody.children).find(row => row.dataset.type === 'goal');
      const startIndex = Array.from(tbody.children).indexOf(startRow);
      const endIndex = Array.from(tbody.children).indexOf(endRow);

      if (targetIndex <= startIndex || targetIndex >= endIndex) return;

      const rect = targetRow.getBoundingClientRect();
      const midY = rect.top + rect.height / 2;
      
      if (e.clientY < midY) {
        targetRow.parentNode.insertBefore(draggedRow, targetRow);
      } else {
        targetRow.parentNode.insertBefore(draggedRow, targetRow.nextSibling);
      }
    });

    tbody.addEventListener('dragend', (e) => {
      if (!draggedRow) return;
      
      draggedRow.classList.remove('dragging');
      const newIndex = Array.from(tbody.children).indexOf(draggedRow);
      
      if (newIndex !== originalIndex) {
        this.updateTrackOrder(tbody);
      }
      
      draggedRow = null;
      originalIndex = null;
    });
  }

  updateTrackOrder(tbody) {
    if (!this.trackMode || !this.trackHandles) return;

    const newOrder = Array.from(tbody.children)
        .map(row => parseInt(row.dataset.waypointIndex))
        .filter(index => !isNaN(index));

        this.trackHandles.forEach(handle => {
        if (handle.autoInserted) {
            this.map.removeLayer(handle);
        }
    });

    const newHandles = [];
    newOrder.forEach((waypointIndex) => {
        const existingHandle = this.trackHandles.find(h => h.attachedWaypointIndex === waypointIndex);
        if (existingHandle) {
            newHandles.push(existingHandle);
        }
    });
    
    this.trackHandles = newHandles;
    
    const finalHandles = [];
    for (let i = 0; i < newHandles.length; i++) {
        finalHandles.push(newHandles[i]);
        
        
        if (i < newHandles.length - 1) {
            const currentHandle = newHandles[i];
            const nextHandle = newHandles[i + 1];
            const distance = currentHandle.getLatLng().distanceTo(nextHandle.getLatLng());
            
            if (distance > 800) {
                const midLatLng = L.latLng(
                    (currentHandle.getLatLng().lat + nextHandle.getLatLng().lat) / 2,
                    (currentHandle.getLatLng().lng + nextHandle.getLatLng().lng) / 2
                );
                const midHandle = L.marker(midLatLng, {
                    icon: handleIcon,
                    draggable: true
                }).addTo(this.map);
                midHandle.attachedWaypointIndex = null;
                midHandle.autoInserted = true;
                this.attachHandleEvents(midHandle);
                finalHandles.push(midHandle);
            }
        }
    }

    this.trackHandles = finalHandles;
    this._updateCurrentTrackFromHandles();
    this.showWaypoints();
  }

 
  showTracks() {
   
  }
}
