import TrackMap3DHandler from "../utils/TrackMap3DHandler.js"
import ExternalTrackMap3DHandler from "../utils/ExternalTrackMap3DHandler.js"
import "leaflet-easybutton/src/easy-button.css"
import "leaflet-easybutton/src/easy-button"
import MiniMap from 'leaflet-minimap';
import "leaflet-polylinedecorator";
import LiveChatWidget from "../utils/LiveChatWidget"
import moment from "moment";

export default class TrackMap {
    constructor() {
        let self = this;

        this.mode = null;
        this.isSubPath = false;
        this.subPoints = [];
        this.subDecorator = null;

        this.handler = null;
        this.track_details = null;
        this.map = null;
        this.points = [];
        this.maxHeight = null;
        this.minHeight = null;
        this.stepHeight = null;

        this.percentage = 0.5;
        this.lastIconPos = 0;

        this.iconSize_x = function () { return this.calcByPercent(96) };
        this.iconSize_y = function () { return this.calcByPercent(109) };

        this.shadowSize_x = function () { return this.calcByPercent(90) };
        this.shadowSize_y = function () { return this.calcByPercent(39) };

        this.iconAnchor_x = function () { return this.calcByPercent(48) };
        this.iconAnchor_y = function () { return this.calcByPercent(109) };

        this.shadowAnchor_x = function () { return this.calcByPercent(0) };
        this.shadowAnchor_y = function () { return this.calcByPercent(39) };

        this.markerIcons = [];

        this.closeTileSelect = function () {
            window.$("#layoutDropdown").dropdown('hide');
        }

        this.resetSizeMap = function () {
            self.map.invalidateSize();
        }

        this.marker_icon = function () {
            return L.icon({
                iconUrl: this.marker_pointer,
                shadowUrl: this.marker_pointer_shadow,

                iconSize: [this.iconSize_x(), this.iconSize_y()], // size of the icon
                shadowSize: [this.shadowSize_x(), this.shadowSize_y()], // size of the shadow
                iconAnchor: [this.iconAnchor_x(), this.iconAnchor_y()], // point of the icon which will correspond to marker's location 99*66
                shadowAnchor: [this.shadowAnchor_x(), this.shadowAnchor_y()],  // the same for the shadow
            });
        };


        this.maxPin = 10;

        this.readState = "pause";
        this.currentPosition = 0;
        this.subCurrentPosition = 0;
        this.currentTime = 0;
        this.readMultiplier = 1;

        $("#current-multiplier").html("x" + this.readMultiplier + "");

        this.ticker = null;

        this.path = null;
        this.pathBorder = null;
        this.subPath = null;
        this.marker = null;
        this.marker_pointer = "";
        this.marker_pointer_shadow = "";

        this.tiles = this.getTiles();
        let tileId = 0;
        try {
            tileId = window.application.isPremium() ? (localStorage.getItem('tileId') ? localStorage.getItem('tileId') : 0) : 0;
            if (this.tiles[tileId] === undefined) {
                tileId = 0;
            }
            localStorage.setItem('tileId', tileId);
        }
        catch (e) { }
        this.currentTile = this.buildTile(this.tiles[tileId]);
        this.currentTileCopy = this.buildTile(this.tiles[tileId]);
        this.miniMap = new MiniMap(this.currentTile);
        this.liveChat = new LiveChatWidget();

        this.bindEvents();
    }

    calcByPercent(x) {
        return Math.round(x * this.percentage)
    }

    setMarkerSize(altitude) {
        let marker_pos = Math.floor((altitude - this.minHeight) / this.stepHeight);
        if (this.lastIconPos !== marker_pos && this.stepHeight !== null && marker_pos < 50) {
            this.lastIconPos = marker_pos;
            this.marker.setIcon(this.markerIcons[marker_pos]);
        }
    }

    setIcons() {
        this.markerIcons = [];
        for (var i = 0; i < 50; i++) {
            this.percentage = 0.2 + (i * 0.016);
            this.markerIcons[i] = this.marker_icon();
        }
    }

    bindEvents() {
        var self = this;
        window.application.setOnDataChangeListener(this);

        $(document).unbind("click", this.closeTileSelect).bind("click", this.closeTileSelect);

        $("#play-pause-btn").unbind("click").click(function (e) {
            e.preventDefault();
            if (!self.isSubPath) {
                if (self.currentPosition >= self.points.length - 1) {
                    self.currentPosition = 0;
                    $("#replay-position").val(0);
                    self.redrawPath();
                }
            } else {
                if (self.subCurrentPosition >= self.subPoints.length - 1) {
                    self.subCurrentPosition = 0;
                    $("#replay-position").val(0);
                    self.redrawPath();
                }
            }
            if (self.readState == "pause") {
                self.readState = "play";
            }
            else {
                self.readState = "pause";
            }

            self.onReadStateChanged();
        });

        $("#active_dark_mode").unbind("change").change(function (e) {
            if ($(this).val() != self.currentPosition) {
                self.currentPosition = parseInt($(this).val());
                self.redrawPath();
            }
        });

        $("#replay-position").unbind("change").change(function (e) {
            if (!self.isSubPath) {
                if ($(this).val() != self.currentPosition) {
                    self.currentPosition = parseInt($(this).val());
                    self.redrawPath();
                }
            } else {
                if ($(this).val() != self.subCurrentPosition) {
                    self.subCurrentPosition = parseInt($(this).val());
                    self.redrawPath();
                }
            }
        });

        $("#stop-btn").unbind("click").click(function (e) {
            e.preventDefault();
            self.stopReplay();
        });

        $("#current-multiplier").unbind("click").click(function (e) {
            self.setMultiplier(1);
        });

        $("#multiplier-btn").unbind("click").click(function (e) {
            e.preventDefault();
            self.setMultiplier();
        });

        $(".js-tile-select").unbind("click").click(function (e) {
            e.preventDefault();
            self.setTile($(this).data("tile-id"));
        })
    }

    setMultiplier(setTo = null) {
        let self = this;

        if (setTo == null) {
            if (this.readMultiplier < 256) {
                this.readMultiplier = this.readMultiplier * 2;
            }
            else {
                this.readMultiplier = 1;
            }
        } else {
            this.readMultiplier = setTo;
        }

        $("#current-multiplier").html("x" + this.readMultiplier + "");
        if (this.readState == "play") {
            clearInterval(this.ticker);
            this.ticker = setInterval(function () {
                self.onTick();
            }, 1000 / this.readMultiplier);
        }

        this.restyle_marker_transition(1 / this.readMultiplier);
    }

    onReadStateChanged() {
        var self = this;
        if (this.readState == "play") {
            this.restyle_marker_transition(1 / this.readMultiplier);
            $("#play-pause-btn").find("i").removeClass("mdi-play").addClass("mdi-pause");
            this.ticker = setInterval(function () {
                self.onTick();
            }, 1000 / this.readMultiplier);
        }
        else {
            this.restyle_marker_transition(0);
            $("#play-pause-btn").find("i").removeClass("mdi-pause").addClass("mdi-play");
            if (this.ticker != null) {
                clearInterval(this.ticker);
                this.ticker = null;
            }
        }
    }

    onTick() {
        var pos = this.currentPosition;
        if (this.isSubPath) {
            pos = this.subCurrentPosition;
        }

        this.previousPosition = pos;
        pos = (pos + 1); 

        if (this.isSubPath) {
            this.subCurrentPosition = pos;
        } else {
            this.currentPosition = pos;
        }

        $("#replay-position").val(pos);
        $("#current-time").text(this.toHHMMSS(this.currentTime));
        this.currentTime++;
        this.liveChat.setCurrentTick(this.currentTime);

        var point = [];
        if (!this.isSubPath) {
            point = this.points[pos];
        } else {
            point = this.subPoints[pos];
        }

        if (point !== undefined) {
            var latLng = [point.latitude, point.longitude];

            if (!this.isSubPath) {
                this.path.addLatLng(latLng);
                this.pathBorder.addLatLng(latLng);
            }

            this.marker.setLatLng(latLng);
            this.setMarkerSize(point.altitude);

            let easeLinearity;

            switch (this.readMultiplier) {
                case 2:
                    easeLinearity = 1;
                    break;
                case 4:
                    easeLinearity = 0.6;
                    break;
                case 8:
                    easeLinearity = 0.5;
                    break;
                case 16:
                    easeLinearity = 0.4;
                    break;
                case 32:
                    easeLinearity = 0.3;
                    break;
                case 64:
                    easeLinearity = 0.2;
                    break;
                default:
                    easeLinearity = 1;
                    break;
            }

            this.map.setView([point.latitude, point.longitude], this.map.getZoom(), {
                "animate": true,
                "pan": {
                    "duration": 1,
                    easeLinearity: easeLinearity
                }
            });
            if (this.track_details !== null) {
                this.track_details.onTick(pos);
            }
        } else {
            if (this.readState == "pause") {
                this.readState = "play";
            }
            else {
                this.readState = "pause";
            }

            this.onReadStateChanged();
        }
    }

    stopReplay() {
        if (this.readState === "play") {
            this.readState = "pause";
            this.onReadStateChanged();
        }

        if (!this.isSubPath) {
            this.currentPosition = 0;
            if (this.track_details !== null) {
                this.track_details.onTick(this.currentPosition);
            }
        } else {
            this.subCurrentPosition = 0;
            if (this.track_details !== null) {
                this.track_details.onTick(this.subCurrentPosition);
            }
        }

        this.setTimers();
        this.redrawPath();
        this.centerView();
    }

    redrawPath() {
        if (!this.points || this.points.length == 0) {
            return;
        }
        if (this.path != null) {
            this.path.remove(this.map);
            this.pathBorder.remove(this.map);
            this.path = null;
            this.pathBorder = null;
        }

        if (this.subDecorator !== null) {
            this.map.removeLayer(this.subDecorator);
            this.subDecorator.remove(this.map);
            this.subDecorator = null;
        }

        if (this.subPath !== null) {
            this.subPath.remove(this.map);
            this.subPath = null;
        }

        var latlngs = this.points.slice(0, this.currentPosition).map(a => [a.latitude, a.longitude]);

        this.pathBorder = L.polyline(latlngs, { color: 'white', weight: 3 }).addTo(this.map);
        this.path = L.polyline(latlngs, { color: 'red', weight: 2 }).addTo(this.map);

        if (!this.isSubPath) {
            var point = this.points[this.currentPosition];
            var latLng = [point.latitude, point.longitude];
            this.marker.setLatLng(latLng);
            this.setMarkerSize(point.altitude);
        }

        if (this.isSubPath) {
            var sublatlngs = this.subPoints.map(a => [a.latitude, a.longitude]);
            this.subPath = L.polyline(sublatlngs, { color: 'green', weight: 2 }).addTo(this.map);
            this.subDecorator = L.polylineDecorator(this.subPath, {
                patterns: [
                    {
                        offset: 0,
                        repeat: 100,
                        symbol: L.Symbol.arrowHead({ pixelSize: 10, polygon: false, pathOptions: { stroke: true, color: "green" } })
                    }
                ]
            }).addTo(this.map);

            var subpoint = this.subPoints[this.subCurrentPosition];
            var sublatLng = [subpoint.latitude, subpoint.longitude];
            this.marker.setLatLng(sublatLng);
            this.setMarkerSize(subpoint.altitude);
        }
    }

    centerView() {
        if (this.marker && this.map) {
            this.map.setView([this.marker.getLatLng().lat, this.marker.getLatLng().lng], this.map.getZoom(), {
                "animate": true,
                "pan": {
                    "duration": 1
                }
            });
        }
    }

   async onDataChanged(data, track_details = null, isExternal = false) {
        this.mode = data.mode;
        this.track = data.track;
        this.track_details = track_details;
        if (this.mode == "2D") {
            this.setSplitter();
            this.points = data.points;
            this.setHeights(this.points);
            this.marker_pointer = data.marker_pointer;
            this.marker_pointer_shadow = data.marker_pointer_shadow;
            this.currentPosition = data.points.length - 1;
            $("#replay-position").val(this.currentPosition);
            this.setTimers();
            this.initMap();
            this.map.fitBounds(this.path.getBounds(), { padding: [50, 50] });

            this.setIcons();

            $(window).unbind("resize", this.resetSizeMap).bind("resize", this.resetSizeMap);           
        }
        else {
            this.handler = isExternal ? new ExternalTrackMap3DHandler(data.first_appear_ad, data.frequency_ad) : new TrackMap3DHandler(data.first_appear_ad, data.frequency_ad);
            await this.handler.init(data);
            await this.liveChat.initTimelineComments();
            this.handler.onTickChanges((currentTime) => {
                this.currentTime = currentTime;
                this.liveChat.setCurrentTick(currentTime);
            });
            this.liveChat.subscribeToPlayerData();
        }
    }

    updatePoints(points) {
        this.isSubPath = true;
        this.subPoints = points;
        this.stopReplay();
        this.subCurrentPosition = points.length - 1;
        this.currentPosition = this.points.length - 1;
        this.setHeights(this.subPoints);
        this.redrawPath();
        this.map.fitBounds(this.subPath.getBounds(), { padding: [50, 50] });

        $("#replay-position").attr("max", points.length);
        var point = this.subPoints[0];
        var latLng = [point.latitude, point.longitude];
        this.setMarkerSize(point.altitude);
        this.marker.setLatLng(latLng);
        this.setTimers();
    }

    setTimers() {
        let firstPoint = this.points[0];
        let lastPoint = this.points[this.points.length - 1];

        if (this.isSubPath) {
            firstPoint = this.subPoints[0];
            lastPoint = this.subPoints[this.subPoints.length - 1];
        }

        let durationInSeconds = lastPoint && firstPoint ? lastPoint.date - firstPoint.date : 0;
        this.currentTime = 0;
        $("#current-time").text("00:00");
        $("#total-time").text(this.toHHMMSS(durationInSeconds));
        this.liveChat.setCurrentTick(this.currentTime);
    }

    toHHMMSS(secs) {
        const hours = Math.floor(secs / 3600);
        const minutes = Math.floor((secs % 3600) / 60);
        const seconds = secs % 60;

        const formattedHours = hours.toLocaleString(I18n.locale); 
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const formattedSeconds = seconds.toString().padStart(2, '0');

        if (hours >= 12) {
            return `${formattedHours}h${formattedMinutes}m`;
        } else {
            return `${formattedHours}h${formattedMinutes}m${formattedSeconds}s`;
        }
    }

    getTooltipContent(p) {
        return "<b>Date:</b> " + moment.unix(p.date).format('LLLL:ss') + "<br/>" +
            "<b>User Speed:</b> " + (p.speed != null ? Math.round(p.speed * 3.6, 2) : 0) + "km/h<br/>" +
            "<b>User Altitude:</b> " + (p.altitude != null ? p.altitude : 0) + "m<br/>" +
            "<b>User Elevation:</b> " + (p.elevation != null ? p.elevation : 0) + "m<br/>" +
            "<b>Wind Speed:</b> " + (p.ws != null ? Math.round(p.ws * 3.6, 2) : 0) + "km/h<br/>" +
            "<b>Wind Quality:</b> " + (p.wq != null ? p.wq : 0) +
            "<br/><b>Wind Bearing:</b> " + (p.wb != null ? Math.round(p.wb, 0) : 0);
    }

    initMap() {
        let self = this;

        var firstPoint = this.points[0];
        var height = $('#A').height(); 

        $("#map").height(height);

        this.map = L.map('map').setView([firstPoint.latitude, firstPoint.longitude], 16);
        var latlngs = this.points.slice(0, this.currentPosition).map(a => [a.latitude, a.longitude]);

        this.pathBorder = L.polyline(latlngs, { color: 'white', weight: 3 }).addTo(this.map);
        this.path = L.polyline(latlngs, { color: 'red', weight: 2 }).addTo(this.map);
        this.subPath = L.polyline([], { color: 'green', weight: 2 }).addTo(this.map);

        var p = this.points[0];
        this.marker = L.marker([parseFloat(p.latitude), parseFloat(p.longitude)], { icon: this.marker_icon() });
        this.marker.addTo(this.map);
        this.scale = L.control.scale().addTo(this.map);

        var layoutButton = L.easyButton('mdi mdi-layers-outline fs-22 text-gray', function (btn, map) {

        });

        var centerButton = L.easyButton('mdi mdi-target fs-22 text-gray', function (btn, map) {
            self.map.setView(self.marker.getLatLng(), 16, {
                "animate": true,
                "pan": {
                    "duration": 1
                }
            });
        });

        $("#full_screen_link").attr("href", $("#url").data("url"));

        var trackCenterButton = L.easyButton('<span class="fs-14 mt-1 text-gray ff-lato px-2"><i class="fas fa-route mr-2"></i>' + I18n.t("website.track.show.map.see_track") + '</span>', function (btn, map) {
            self.isSubPath = false;
            self.subPoints = [];
            self.subCurrentPosition = 0;

            self.points = self.track_details.points;
            self.setHeights(self.points);
            self.stopReplay();
            self.currentPosition = self.points.length - 1;
            $("#replay-position").val(self.currentPosition);
            self.redrawPath();

            self.percentage = 0.5;
            self.marker.setIcon(self.marker_icon());
            self.setMarkerSize(self.points[0].altitude);
            self.marker.setLatLng([self.points[0].latitude, self.points[0].longitude]);
            self.map.fitBounds(self.path.getBounds(), { padding: [50, 50] });
            self.setTimers();
            self.track_details.displayGraph(self.points, null);
        });

        var navBar1 = L.easyBar([layoutButton], { position: 'topright' });
        var navBar2 = L.easyBar([centerButton], { position: 'topleft' });
        /*var navBar3 = L.easyBar([ statsButton ], {position: 'topright'});*/
        var navBar4 = L.easyBar([trackCenterButton], { position: 'topright' });
        /*navBar3.addTo(this.map);*/
        navBar2.addTo(this.map);
        navBar4.addTo(this.map);
        navBar1.addTo(this.map);

        if (window.innerWidth - $("#map").offset().left > 700) {
            this.miniMap.addTo(this.map);
        }

        $(layoutButton.button).data("toggle", "dropdown");
        $(layoutButton.button).attr("id", "layoutDropdown");
        $(layoutButton.button).attr("type", "button");
        $(layoutButton.button).attr("aria-haspopup", "true");
        $(layoutButton.button).attr("aria-expanded", "false");
        $(layoutButton.button).parent().addClass("dropdown");
        $(layoutButton.button).after(this.buildDropdown());

        window.$(layoutButton.button).dropdown();

        this.setTile(localStorage.getItem("tileId"));

        this.restyle_marker_transition(0);
        this.bindEvents();
        var elem = L.DomUtil.get('dropdown-map-tiles');
        L.DomEvent.on(elem, 'wheel', L.DomEvent.stopPropagation);
        L.DomEvent.on(elem, 'touchstart', L.DomEvent.stopPropagation);
    }

    restyle_marker_transition(transition) {
        $(".leaflet-marker-pane > *").css({
            '-webkit-transition': 'transform ' + transition + 's linear',
            '-moz-transition': 'transform ' + transition + 's linear',
            '-o-transition': ' transform ' + transition + 's linear',
            '-ms-transition': 'transform ' + transition + 's linear',
            'transition': 'transform ' + transition + 's linear',
        });
        $(".leaflet-marker-shadow").css({
            '-webkit-transition': 'transform ' + transition + 's linear',
            '-moz-transition': 'transform ' + transition + 's linear',
            '-o-transition': ' transform ' + transition + 's linear',
            '-ms-transition': 'transform ' + transition + 's linear',
            'transition': 'transform ' + transition + 's linear',
        });
    }

    buildDropdown() {
        let dropDown = "<div id=\"dropdown-map-tiles\" class=\"dropdown-menu dropdown-menu-right py-0\" aria-labelledby=\"layoutDropdown\">\n<div class=\"scrollbar-tiles scrollbar-primary overflow-auto\">";

        this.tiles.forEach(function (item) {
            dropDown += "    <a class=\"dropdown-item h-100 w-100 fs-14 py-1 js-tile-select\" data-tile-id=\"" + item.id + "\">" + item.name + "</a>\n"
        });

        dropDown += " </div> </div>";
        return dropDown;
    }

    setTile(tileId) {
        $(".js-tile-select[data-tile-id=" + localStorage.getItem('tileId') + "]").data("selected", 0).removeClass("bg-secondary").removeClass("text-light");
        $(".js-tile-select[data-tile-id=" + tileId + "]").data("selected", 1).addClass("bg-secondary").addClass("text-light");
        this.map.removeLayer(this.currentTile);
        this.currentTile = this.buildTile(this.tiles[tileId]);
        //this.currentTile.on("load",function() { });
        if (window.innerWidth - $("#map").offset().left > 700) {
            this.currentTileCopy = this.buildTile(this.tiles[tileId]);
            this.miniMap.changeLayer(this.currentTileCopy);
        }
        this.map.addLayer(this.currentTile);
        localStorage.setItem('tileId', tileId);
    }

    buildTile(tile) {
        return new L.tileLayer(tile.url, tile.options);
    }

    getTiles() {
        return [            
            {
                id: 0,
                name: 'OSM-Carto based retina maps',
                url: 'https://tile.tracestrack.com/topo__/{z}/{x}/{y}.png?key=5b37c4cf109fa26755308c3a5e221369',
                options: {
                    maxZoom: 17,
                    attribution: 'Data: © OpenStreetMap contributors, SRTM, NASADEM; Maps © Tracestrack'
                }
            },
            {
                id: 1,
                name: 'OpenStreetMaps',
                url: 'https://{s}.tile.openstreetmap.{ext}/{z}/{x}/{y}.png',
                options: {
                    ext: function () {
                        return ['org', 'de'][Math.floor(Math.random() * Math.floor(2))]
                    },
                    maxZoom: 18,
                    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                }
            },
            {
                id: 2,
                name: 'OpenTopoMap',
                url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
                options: {
                    maxZoom: 17,
                    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                }
            },        
            {
                id: 3,
                name: 'OpenTopoMap based Retina maps',
                url: 'https://tile.tracestrack.com/topo__/{z}/{x}/{y}.png?key=5b37c4cf109fa26755308c3a5e221369',
                options: {
                    maxZoom: 17,
                    attribution: 'Data: © OpenStreetMap contributors, SRTM, NASADEM; Maps © Tracestrack'
                }
            },    
            {
                id: 4,
                name: 'GoogleTerrain',
                url: 'https://mt{s}.google.com/vt/lyrs=p&hl=en&x={x}&y={y}&z={z}&s=Ga',
                options: {
                    subdomains: '012',
                    maxZoom: 20,
                    attribution: ''
                }
            },
            {
                id: 5,
                name: 'GoogleSatellite',
                url: 'https://mt{s}.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}&s=Ga',
                options: {
                    subdomains: '012',
                    maxZoom: 20,
                    attribution: ''
                }
            },
            {
                id: 6,
                name: 'EsriTopo',
                url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}',
                options: {
                    maxZoom: 19,
                    attribution: ''
                }
            },            
            {
                id: 7,
                name: 'MapyBaseTopo',
                url: 'https://m{s}.mapserver.mapy.cz/base-m/{z}-{x}-{y}',
                options: {
                    subdomains: '123',
                    maxZoom: 19,
                    attribution: ''
                }
            },
            {
                id: 8,
                name: 'Maptoolkit',
                url: 'https://tile{s}.maptoolkit.net/terrain/{z}/{x}/{y}.png',
                options: {
                    subdomains: '012',
                    maxZoom: 19,
                    attribution: ''
                }
            },            
        ]
    }

    getNonPremiumTiles() {
        return [            
            {
                id: 0,
                name: 'OpenStreetMaps',
                url: 'https://{s}.tile.openstreetmap.{ext}/{z}/{x}/{y}.png',
                options: {
                    ext: function () {
                        return ['org', 'de'][Math.floor(Math.random() * Math.floor(2))]
                    },
                    maxZoom: 18,
                    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                }
            },
            {
                id: 1,
                name: 'OSM-Carto based retina maps',
                url: 'https://tile.tracestrack.com/_/{z}/{x}/{y}.png?key=5b37c4cf109fa26755308c3a5e221369',
                options: {
                    maxZoom: 17,
                    attribution: 'SportsTrackLive - &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                }
            },            
            {
                id: 2,
                name: 'OpenTopoMap',
                url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
                options: {
                    maxZoom: 17,
                    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                }
            },
            {
                id: 3,
                name: 'OpenTopoMap based retina maps',
                url: 'https://tile.tracestrack.com/topo__/{z}/{x}/{y}.png?key=5b37c4cf109fa26755308c3a5e221369',
                options: {
                    maxZoom: 17,
                    attribution: 'Tracestrack - &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                }
            },
            {
                id: 4,
                name: 'GoogleTerrain',
                url: 'https://mt{s}.google.com/vt/lyrs=p&hl=en&x={x}&y={y}&z={z}&s=Ga',
                options: {
                    subdomains: '012',
                    maxZoom: 20,
                    attribution: ''
                }
            },
            {
                id: 5,
                name: 'GoogleSatellite',
                url: 'https://mt{s}.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}&s=Ga',
                options: {
                    subdomains: '012',
                    maxZoom: 20,
                    attribution: ''
                }
            },
        ];
    }

    setHeights(points) {
        this.maxHeight = null;
        this.minHeight = null;
        let self = this;
        points.forEach(function (item) {
            let height = parseInt(item.altitude);
            if (self.maxHeight === null || self.maxHeight < height) {
                self.maxHeight = height;
            }
            if (self.minHeight === null || self.minHeight > height) {
                self.minHeight = height;
            }
        });

        var diffHeight = this.maxHeight - this.minHeight;
        if (diffHeight > 50) {
            this.stepHeight = (this.maxHeight - this.minHeight) / 48.0;
        } else {
            this.stepHeight = null;
        }
    }

    onDestroy() {
        this.stopReplay();
        this.readMultiplier = 1;
        if (this.map) {
        this.map.remove();
        }

        $(document).unbind("click", this.closeTileSelect);
        $(window).unbind("resize", this.resetSizeMap);
        $("#play-pause-btn").unbind("click");
        $("#active_dark_mode").unbind("change");
        $("#replay-position").unbind("change");
        $("#stop-btn").unbind("click");
        $("#current-multiplier").unbind("click");
        $("#multiplier-btn").unbind("click");
        $(".js-tile-select").unbind("click");

        this.liveChat.destroy();

        this.handler && this.handler.onTimerChanges && this.handler.onTimerChanges(() => {});
    }

    onChartHover(index) {
        if (!this.isSubPath) {
            if (this.currentPosition === this.points.length || this.currentPosition === this.points.length - 1) {
                this.marker.setLatLng([this.points[parseInt(index)].latitude, this.points[parseInt(index)].longitude]);
                this.setMarkerSize(this.points[parseInt(index)].altitude);
            }
        } else {
            if (this.subCurrentPosition >= this.subPoints.length - 1 || this.subCurrentPosition === 0) {
                this.marker.setLatLng([this.subPoints[parseInt(index)].latitude, this.subPoints[parseInt(index)].longitude]);
                this.setMarkerSize(this.subPoints[parseInt(index)].altitude);
            }
        }
    }

    onChartClick(index) {
        if (!this.isSubPath) {
            this.currentPosition = index;
            this.currentTime = this.points[this.currentPosition].date - this.points[0].date;
        } else {
            this.subCurrentPosition = parseInt(index);
            this.currentTime = this.subPoints[this.subCurrentPosition].date - this.subPoints[0].date;
        }

        $("#current-time").text(this.toHHMMSS(this.currentTime));
        this.liveChat.setCurrentTick(this.currentTime);
        this.redrawPath();
        this.centerView();
    }

    setSplitter() {
        let self = this;
        let A = parseInt($('#A').height(), 10),
            Z = parseInt($('#Z').height(), 10),
            offset = $('#container').offset(),
            splitter = function (event, ui) {
                var aw = parseInt(ui.position.top);
                $('#A').css({ height: aw });
                $("#map").css({ height: $('#A').height() });
                self.map.invalidateSize();
            };

        window.$('#Z').draggable({
            axis: 'y',
            handle: '#handler',
            containment: [
                offset.left,
                offset.top + 400,
                offset.left + $('#container').width(),
                offset.top + A - Z + 1000
            ],
            drag: splitter
        });

    }
}