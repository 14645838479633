import ConfirmationModal from "../../common/ConfirmationModal";
import ToastNotification from "../utils/ToastNotification";
import EditTimelineItem from "./EditTimelineItem";
import CompareTracks from "../utils/CompareTracks";
import * as select2 from "select2";

export default class ListTrack {
    constructor() {
        this.page = 1;
        this.user_id = null;
        this.fatherClass = null;
        this.timerId = null;
        this.user_activities = null;
        this.confirmationModal = new ConfirmationModal();
        this.editTimelineItem = new EditTimelineItem();
        this.compareTracks = new CompareTracks();
        this.user_activities_ids = [];

        this.features = null;
        this.tags = null;

        this.filter_activity = 0;
        this.filter_type = cookies.get("filter_type") ? parseInt(cookies.get("filter_type")) : 0;
    }

    onDataChanged(data) {
        this.user_id = data.user_id;
        this.user_activities_ids = data.user_activities_ids.map(c => c.id);
        this.user_activities = data.user_activities_ids;
        this.filter_activity = cookies.get("activity_preference") && this.user_activities_ids.includes(parseInt(cookies.get("activity_preference"))) ? parseInt(cookies.get("activity_preference")) : 0;
        this.setSelect2();
        this.resetList();
        this.bindEvents();
        this.setDefaultValuesToFilters();
        this.editTimelineItem.onDataChanged(data);

        let name = I18n.t("website.user.show.filter.all_activities");
        if (this.filter_activity !== 0) {
            let act = this.user_activities.filter(act => act.id === this.filter_activity);
            if (act.length > 0) {
                name = I18n.t("track_categories." + act[0].name);
            }
        }
        $("#filter-activity-btn").text(name);
    }

    setFatherClass(fatherClass) {
        this.fatherClass = fatherClass;
    }

    bindEvents() {
        $(document).on("turbolinks:before-cache", () => {
            if ($('#filter_track_features').hasClass("select2-hidden-accessible")) {
                $('#filter_track_features').select2('destroy');
                $('#filter_track_features').off('change');
            }

            if ($('#filter_track_tags').hasClass("select2-hidden-accessible")) {
                $('#filter_track_tags').select2('destroy');
                $('#filter_track_tags').off('change');
            }
        });

        $(".js-filter-activity").off("click").on("click", (event) => {
            event.preventDefault();
            this.updateCategoryFilter($(event.currentTarget).data("id"), $(event.currentTarget).data("name-hr"));
        });

        $(".js-filter-type").off("click").on("click", (event) => {
            event.preventDefault();
            this.updateTypeFilter($(event.currentTarget).data("id"), $(event.currentTarget).data("name-hr"));
        });

        const onChangeTags = () => {
            this.tags = $('#filter_track_tags').select2('data').map(a => a.id);
            this.allowFilterApplying();
        }

        const onChangeFeatures = () => {
            this.features = $('#filter_track_features').select2('data').map(a => a.id);
            this.allowFilterApplying();
        }

        $("#filter_track_tags").on("select2:select", onChangeTags).on("select2:unselect", onChangeTags);
        $("#filter_track_features").on("select2:select", onChangeFeatures).on("select2:unselect", onChangeFeatures);

        $("#filter_form").off("submit").on("submit", (e) => {
            e.preventDefault();
            this.onFilterSubmit();
        });
    }

    updateCategoryFilter(id, name) {
        this.allowFilterApplying();

        if (this.filter_activity !== id) {
            cookies.create("activity_preference", id);
            this.filter_activity = id;
            $("#filter-activity-btn").text(name);
        }
    }

    updateTypeFilter(id, name) {
        this.allowFilterApplying();

        if (this.filter_type !== id) {
            cookies.create("filter_type", id);
            this.filter_type = id;
            $("#filter-type-btn").text(name);
        }
    }

    allowFilterApplying() {
        $("#filter_submit").removeAttr("disabled");
    }

    resetList() {
        $(window).off('scroll', this.onScroll);
        $("#track_listing").empty();
        this.page = 1;
        if (!(this.timerId == null)) {
            clearTimeout(this.timerId);
        }
        this.timerId = setTimeout(() => {
            this.page = 1;
            this.load_tracks();
        }, 500);
    }

    load_tracks() {
        $(window).off('scroll', this.onScroll);
        let self = this;
        var per_page = 6;

        $("#user_track_loader").removeClass("d-none");
        $("#filter_empty").addClass("d-none")

        let requestData = {
            user_id: this.user_id,
            page: this.page,
            filter_activity: this.filter_activity,
            filter_type: this.filter_type,
            filter_features: this.features,
            filter_tags: this.tags,            
            per_page: per_page
        }

        if (application.host_used == "birdtracking") {
            requestData.filter_scientific = true;
        }

        $.ajax({
            url: "/user/ajax_tracks",
            type: "get",
            data: requestData,
            success: function (data) {
                var before = $(".timeline_item").length;
                $("#track_listing").append(data);

                $(".js-activity-badge").off("click").on("click", function () {
                    self.select_activity(this)
                });

                $(".js-track-date").off("click").on("click", function (e) {
                    e.preventDefault();
                    self.select_date(this)
                });

                var after = $(".timeline_item").length;
                window.application.current_handler.onUpdate();

                if (after === 0 && self.features === null && self.tags === null) {
                    $("#user_empty").removeClass("d-none")
                } else if (after === 0 && (self.features !== null || self.tags !== null)) {
                    $("#filter_empty").removeClass("d-none")
                }

                if (before !== after) {
                    self.page++;

                    $(window).on('scroll', self.onScroll);

                    $(function () {
                        window.$('[data-toggle="tooltip"]').tooltip()
                    });
                } else {
                    $(window).off('scroll', self.onScroll);
                    $("#user_track_loader").addClass("d-none");
                }

                self.reloadEvents();
            },
            error: function (data) {
                $("#user_track_loader").addClass("d-none");
            }
        });
    }

    onFilterSubmit() {
        this.resetList();
    }

    onScroll() {
        if ($(window).scrollTop() >= $('#track_listing').offset().top + $('#track_listing').outerHeight() - 300 - window.innerHeight) {
            window.application.current_handler.listTrack.load_tracks();
        }
    }

    onDestroy() {
        this.editTimelineItem.onDestroy();
        this.compareTracks.onDestroy();
        $(".js-activity-badge").off("click")
        $(window).off('scroll', this.onScroll);
        $(".js-filter-activity").off("click");

        $('#filter_track_tags').val(null).trigger('change');
        $('#filter_track_tags').empty();
        $('#filter_track_features').val(null).trigger('change');
        $('#filter_track_features').empty();
    }

    select_activity(item) {
        window.scrollTo({
            left: 0,
            top: 0,
            behavior: 'smooth'
        });
        this.updateCategoryFilter($(item).data("id"), $(item).data("name-hr"));
    }

    select_date(item) {
        if ($("#month").val() !== $(item).data("month") - 1 || $("#year").val() !== $(item).data("year")) {
            $("#month").val($(item).data("month") - 1);
            $("#year").val($(item).data("year"));
            $("#dropdownYearSelect").text($(item).data("year"));
        }
        this.fatherClass.calendar.jump($(item).data("day"));
        window.scrollTo({
            left: 0,
            top: $("#calendar").offset().top - 150,
            behavior: 'smooth'
        });
        $("#calendar").addClass("focus");
        setTimeout(() => {
            $("#calendar").removeClass("focus");
        }, 2000);
    }

    getParameter(parameterName) {
        const urlParams = new URLSearchParams(window.location.search);
        const params = urlParams.getAll(parameterName);
        
        if (params.length === 0) {
            return null;
        } else if (params.length === 1) {
            return params[0];
        } else {
            return params;
        }
    }
    

    setSelect2() {
        let self = this;
        let localeDepPath = 'select2/dist/js/i18n/' + I18n.locale;
        require(localeDepPath)

        $('#filter_track_features').select2({
            theme: 'bootstrap4',
            language: I18n.locale,
            tokenSeparators: [","],
            ajax: {
                delay: 250,
                url: window.application.getAPIUrl()+"/v1/users/current_features",
                data: function (params) {
                    let requestData = {
                        user_id: self.user_id,
                        search: params.term,
                        page: params.page || 1
                    };
    
                    if (window.application.host_used == "birdtracking") {
                        requestData.filter_scientific = true;
                    }

                    return requestData;
                },
                beforeSend: function (request) {
                    request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
                },
                processResults: function (data, params) {
                    params.page = params.page || 1;
                    return {
                        results: $.map(data.results, function (obj) {
                            return {
                                "id": obj.id,
                                "text": obj.text
                            };
                        }),
                        pagination: {
                            more: (params.page * 10) < data.count_filtered
                        }
                    };
                },
                transport: function (params, success, failure) {
                    let $request = $.ajax(params);

                    $request.then(success);
                    $request.fail(failure);

                    return $request;
                }
            }
        });

        $('#filter_track_tags').select2({
            theme: 'bootstrap4',
            language: I18n.locale,
            ajax: {
                delay: 250,
                url: window.application.getAPIUrl()+"/v1/users/current_tags",
                data: function (params) {
                    let requestData = {
                        user_id: self.user_id,
                        search: params.term,
                        page: params.page || 1
                    };
    
                    if (window.application.host_used == "birdtracking") {
                        requestData.filter_scientific = true;
                    }

                    return requestData;
                },
                beforeSend: function (request) {
                    request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
                },
                processResults: function (data, params) {
                    params.page = params.page || 1;
                    return {
                        results: $.map(data.results, function (obj) {
                            return {
                                "id": obj.id,
                                "text": obj.text
                            };
                        }),
                        pagination: {
                            more: (params.page * 10) < data.count_filtered
                        }
                    };
                },
                transport: function (params, success, failure) {
                    let $request = $.ajax(params);

                    $request.then(success);
                    $request.fail(failure);

                    return $request;
                }
            }
        });
        $('#filter_track_tags').next().addClass("w-100");
        $('#filter_track_features').next().addClass("w-100");
    }

    setDefaultValuesToFilters() {
        $("#filter_submit").attr("disabled", "true");

        let self = this;
        let features = this.getParameter("features[]");
        let tags = this.getParameter("tags[]");

        if (features) {
            let featuresSelect = $('#filter_track_features');
            $.ajax({
                type: 'GET',
                url: window.application.getAPIUrl() + "/v1/users/" + this.user_id + "/search_features",
                data: {
                    search: features
                },
                beforeSend: function (request) {
                    request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
                },
            }).then(function (data) {
                if (data.results !== undefined) {
                    data.results.forEach(function (item) {
                        let option = new Option(item.text, item.id, true, true);
                        featuresSelect.append(option).trigger('change');
                    })
                    self.features = $('#filter_track_features').select2('data').map(a => a.id);
                    $("#collapseFilters").collapse("show");
                    self.resetList();
                }
            });
        }
        if (tags) {
            let tagsSelect = $('#filter_track_tags');
            $.ajax({
                type: 'GET',
                url: window.application.getAPIUrl() + "/v1/users/" + this.user_id + "/search_tags",
                data: {
                    search: tags
                },
                beforeSend: function (request) {
                    request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
                },
            }).then(function (data) {
                if (data.results !== undefined) {
                    data.results.forEach(function (item) {
                        let option = new Option(item.text, item.id, true, true);
                        tagsSelect.append(option).trigger('change');
                    })
                    $('#filter_track_tags').trigger({
                        type: 'select2:select',
                        params: {
                            data: data
                        }
                    });
                    self.tags = $('#filter_track_tags').select2('data').map(a => a.id);
                    $("#collapseFilters").collapse("show");
                    self.resetList();
                }
            });
        }
    }

    reloadEvents() {
        let self = this;
        this.editTimelineItem.reloadEvents();
        this.compareTracks.reloadEvents();
        window.application.reloadFitty();
        window.application.reloadLazyLoad();
        $('.dropdown-menu.keep-open').unbind("click").on('click', function (e) {
            e.stopPropagation();
        });

        $(".js-destroy-track-item").unbind("click").click(function (e) {
            e.preventDefault();
            let item = this;
            self.confirmationModal.displayConfirm(I18n.t("website.user.destroy_track.title"),
                I18n.t("website.user.destroy_track.message"),
                {
                    positive_btn: {
                        type: "danger",
                        label: I18n.t("website.user.destroy_track.positive_btn"),
                        callback: function () {
                            self.destroy_timeline_item($(item).data("id"), $(item).data("type"));
                        }
                    },
                    negative_btn: {
                        label: I18n.t("website.user.destroy_track.negative_btn")
                    }
                });
        });

        $(".js-destroy-contest-item").unbind("click").click(function (e) {
            e.preventDefault();
            let item = this;
            self.confirmationModal.displayConfirm(I18n.t("website.user.destroy_contest.title"),
                I18n.t("website.user.destroy_contest.message"),
                {
                    positive_btn: {
                        type: "danger",
                        label: I18n.t("website.user.destroy_contest.positive_btn"),
                        callback: function () {
                            self.destroy_timeline_item($(item).data("id"), $(item).data("type"));
                        }
                    },
                    negative_btn: {
                        label: I18n.t("website.user.destroy_contest.negative_btn")
                    }
                });
        });

        $(".js-destroy-scene-item").unbind("click").click(function (e) {
            e.preventDefault();
            let item = this;
            self.confirmationModal.displayConfirm(I18n.t("website.user.destroy_scene.title"),
                I18n.t("website.user.destroy_scene.message"),
                {
                    positive_btn: {
                        type: "danger",
                        label: I18n.t("website.user.destroy_scene.positive_btn"),
                        callback: function () {
                            self.destroy_timeline_item($(item).data("id"), $(item).data("type"));
                        }
                    },
                    negative_btn: {
                        label: I18n.t("website.user.destroy_scene.negative_btn")
                    }
                });
        });


        $(".js-private-track").unbind("change").change(function () {
            let ajaxData = {
                url: window.application.getAPIUrl() + "/v1/track/" + $(this).data("id"),
                data: { track: { privacy_type: ($(this).is(":checked") ? "private_type" : "public_type") } },
                method: "PUT",
                beforeSend: function (request) {
                    request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
                },
            };
            $.ajax(ajaxData).done(function (data) {
                ToastNotification.make(I18n.t("website.notifications.privacy.status"));

            }).fail(function (err) {

            });
        })

        $(".js-private-scene").unbind("change").change(function () {
            let ajaxData = {
                url: window.application.getAPIUrl() + "/v1/scene/" + $(this).data("id"),
                data: { scene: { privacy_type: ($(this).is(":checked") ? "private_type" : "public_type") } },
                method: "PUT",
                beforeSend: function (request) {
                    request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
                },
            };
            $.ajax(ajaxData).done(function (data) {
                ToastNotification.make(I18n.t("website.notifications.privacy.status"));

            }).fail(function (err) {

            });
        })
    }

    destroy_timeline_item(id, type) {
        if (type === "track") {
            let ajaxData = {
                url: window.application.getAPIUrl() + "/v1/track/" + id,
                data: {},
                method: "DELETE",
                beforeSend: function (request) {
                    request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
                },
            };
            $.ajax(ajaxData).done(function (data) {
                $(".track_item[data-id='" + data.id + "']").hide("slow")
                ToastNotification.make(I18n.t("website.track.delete_track.deleted_ok"));
            }).fail(function (err) {
                ToastNotification.make(I18n.t("website.track.delete_track.deleted_ko"));
            });
        } else if (type === "contest") {
                let ajaxData = {
                    url: window.application.getAPIUrl() + "/v1/contest_task/" + id,
                    data: {},
                    method: "DELETE",
                    beforeSend: function (request) {
                        request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
                    },
                };
                $.ajax(ajaxData).done(function (data) {
                    $(".contest_task_item[data-id='" + data.id + "']").hide("slow")
                    ToastNotification.make(I18n.t("website.contest.delete_contest.deleted_ok"));
                }).fail(function (err) {
                    ToastNotification.make(I18n.t("website.contest.delete_contest.deleted_ko"));
                });    
        } else if (type === "scene") {
            let ajaxData = {
                url: window.application.getAPIUrl() + "/v1/scene/" + id,
                data: {},
                method: "DELETE",
                beforeSend: function (request) {
                    request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
                },
            };
            $.ajax(ajaxData).done(function (data) {
                $(".scene_item[data-id='" + data.id + "']").hide("slow")
                ToastNotification.make(I18n.t("website.scene.delete_scene.deleted_ok"));
            }).fail(function (err) {
                ToastNotification.make(I18n.t("website.scene.delete_scene.deleted_ko"));
            });
        }
    }

}